<!--
/**
 * Help template
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */
 -->

<script setup>
    import { reactive } from 'vue';
    import { help } from '?/ProjectData';

    // Data
    const state = reactive({
        help: help
    });
</script>

<template>
    <div id="help-wrapper" class="help-wrapper">
        <div class="help-entry" v-for="help in state.help">
            <button class="help-headline"
                 v-on:click="help.visible = !help.visible">
                <div class="help-label"
                     v-html="help.headline"></div>
                <div class="help-trigger"
                     v-html="(help.visible) ? '▼' : '▲'"></div>
            </button>
            <div class="help-text"
                 v-html="help.text"
                 v-if="help.visible">
            </div>
        </div>
    </div>
</template>
