<!--
/**
 * Single archive template (static content, like archive)
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */
 -->

<script setup>
    import { reactive, onMounted } from 'vue';
    import { useRoute } from 'vue-router';
    import store from '?/Stores';
    import { all as projectData } from '?/ProjectData';
    import repository from '?/Composable/Repository';
    import { routeTo, getAssetPath } from '?/Composable/Tools';
    import Messages from '?/Component/Webapp/Messages';

    // Initialization
    const route = useRoute();

    // Data
    const props = defineProps({
        station: {
            type: Object,
            required: true
        }
    });

    const state = reactive({
        station: props.station,
        stationVisible: false
    });

    const currentStationData = projectData[route.meta.slug];

    // Custom setup logic
    if (
        store.getters.processedStations(route.meta.slug).length &&
        !store.getters.station(route.params.id)
    ) {
        routeTo('/webapp/' + route.meta.slug)
    }

    onMounted(() => {
        state.stationVisible = props.station.visible;

        // Get data from station if messages are empty
        if (
            state.station &&
            !store.getters.messagesforStation(state.station.stationId).length
        ) {
            repository.activateStation(state.station.stationId);
        }
    });

    const stationTitle = (station) => {
        let data = currentStationData
            .filter((item) => {
                return (item.id == station.stationId);
            });

        return (data.length)
            ? (station.done && data.hasOwnProperty('titleDone'))
                ? data[0].titleDone
                : (data[0].title)
                    ? data[0].title
                    : station.name
            : '';
    };

    const stationDescription = (station) => {
        let data = currentStationData
            .filter((item) => {
                return (item.id == station.stationId);
            });

        return (data.length)
            ? (station.done && data.hasOwnProperty('longDescDone'))
                ? data[0].longDescDone
                : data[0].longDesc
            : '';
    };
 </script>

<template>
    <div id="station-archive-detail"
         class="station-detail station-archive-detail station-type__static-content"
         v-if="state.stationVisible">
        <div class="station"
             :id="'station-' + state.station.stationId">
            <h2 class="station-name"
                v-html="stationTitle(state.station)"></h2>
            <figure class="station-image">
                <span class="station-image-wrapper"
                      :style="'background-image: url(\'' + getAssetPath() + '/' + $route.meta.slug + '/station-' + state.station.stationId + '.jpg\');'">
                    <img :src="getAssetPath() + '/' + $route.meta.slug + '/station-' + state.station.stationId + '.jpg'"
                         alt="" />
                </span>
                <figcaption>
                    <div class="station-description"
                         v-html="stationDescription(state.station)"></div>
                </figcaption>
            </figure>
        </div>

        <Messages :stationId="state.station.stationId" />
    </div>
    <div id="station-archive-detail" class="station-detail station-archive-detail" v-else>
        {{ $t('archive.unavailable') }}
    </div>
</template>
