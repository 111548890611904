<!--
/**
 * Privacy template
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */
 -->

<script setup>
    import { reactive } from 'vue';
    import { privacy } from '?/ProjectData';
    import store from '?/Stores';

    // Data
    const state = reactive({
        privacy: privacy
    });
</script>

<template>
    <div class="privacy">
        <div class="content-wrapper">
            <h2>Datenschutz&shy;informationen</h2>
            <p>
                Bei diesem Gratisangebot handelt es sich um ein interaktives Spiel, das in einer WebApp läuft.
            </p>
            <p>
                <strong>Geltungsbereich</strong><br />
                Diese Datenschutzerklärung klärt Nutzer über die Art, den Umfang und Zwecke der Erhebung und Verwendung personenbezogener Daten durch den verantwortlichen Anbieter
            </p>
            <p>
                <strong>Angaben gemäß § 5 TMG</strong><br />
                Schaubühne Lindenfels gemeinnützige Aktiengesellschaft<br />
                Karl-Heine-Straße 50<br />
                04229 Leipzig
            </p>
            <p>
                <strong>Kontakt</strong><br />
                Telefon: 0341 / 48 46 20<br />
                E-Mail: service@schaubuehne.com
            </p>
            <p>
                Vorstand: René Reinhardt<br />
                Sitz der Gesellschaft: Leipzig<br />
                Registergericht: Amtsgericht Leipzig, HRB 21820<br />
                Umsatzsteuer-Identifikationsnummer: DE242665693
            </p>
            <p>
                auf dieser Website (im folgenden “Angebot”) auf.
            </p>
            <p>
                Die rechtlichen Grundlagen des Datenschutzes finden sich im Bundesdatenschutzgesetz (BDSG) und dem Telemediengesetz (TMG).
            </p>
            <h3>Welche Daten erfassen wir von Ihnen?</h3>
            <ul>
                <li>
                    Grundsätzlich erfassen wir nur solche personengebundenen Daten, die für die Durchführung des interaktiven Angebots nötig sind und löschen sie wieder, sobald die Durchführung beendet ist.
                </li>
                <li>
                    Die Zulässigkeit dieser Verarbeitung richtet sich nach Art. 6 Abs. 1 b) DS-GVO, wonach die Verarbeitung rechtmäßig ist, wenn sie für die Erfüllung eines Vertrags, dessen Vertragspartei die betroffene Person ist, oder zur Durchführung vorvertraglicher Maßnahmen erforderlich ist, die auf Anfrage der betroffenen Person erfolgen. Die Bereitstellung der Daten ist erforderlich, um an dem Angebot teilnehmen zu können.
                </li>
                <li>
                    <strong>Wir nutzen keine Cookies.</strong> Um Sie als User zu identifizieren und Ihre Einstellungen zu speichern, nutzen wir automatisiert lediglich den Browserspeicher. IP-Adressen erfassen wir wenn überhaupt nur anonymisiert.
                </li>
                <li>
                    <strong>Wir übermitteln und speichern keine Standortdaten.</strong> Wenn Sie die Standorterkennung in Ihrem Mobilgerät aktivieren, werden Empfehlungen, welche Stationen in Ihrer Nähe verfügbar sind, in ihrem Browser generiert und nicht übermittelt.
                </li>
            </ul>

            <h3>Wofür nutzen wir Ihre Daten?</h3>
            <ul>
                <li>Ausschließlich zur Durchführung des von Ihnen gewählten interaktiven Angebots.</li>
                <li>In besonderen Fällen können Daten auch im gesetzlich vorgesehenen Rahmen zur Auskunftserteilung gegenüber staatlichen Stellen sowie zur Missbrauchserkennung herangezogen werden.</li>
            </ul>
            <h3>Wie können Sie Ihre Daten löschen?</h3>
            <ul>
                <li>Sie können Ihre Daten jederzeit löschen, indem Sie in Ihrem Nutzer-Account das Menü öffnen und <em>Account löschen</em> wählen.</li>
                <li>Eine automatische Löschung Ihrer Daten erfolgt, wenn wir ein Jahr nach Ihrer Registrierung keine Aktivität verzeichnen konnten.</li>
                <!-- <li>
                    Sie können sich auch direkt an uns wenden, die Kontaktdaten finden Sie <a href="#legal-information">hier</a>.
                    Neben der Löschung können Sie sich auch bezüglich Ihres Rechts auf Auskunft, Berichtigung, Sperrung und Widerspruch an uns wenden.
                </li> -->
            </ul>

            <p>
                Zur Durchführung des Angebots bedient sich der Verantwortliche der A.R.S. – Alternate Reality Strategies GmbH, Lützner Str. 85, 04177 Leipzig. Die datenschutzrechtlichen Hinweise der A.R.S. – Alternate Reality Strategies GmbH sind abrufbar unter
                <a href="https://thadeus-roth.de/impressum.php#datenschutz">https://thadeus-roth.de/impressum.php#datenschutz</a>
            </p>
        </div>
    </div>
</template>
