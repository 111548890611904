<!--
/**
 * Single main template (game visibility, like game station)
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */
 -->

<script setup>
    import { reactive, computed, onMounted, onUnmounted, ref, nextTick } from 'vue';
    import { useRoute } from 'vue-router';
    import config from '?/Config';
    import store from '?/Stores';
    import { all as projectData } from '?/ProjectData';
    import { t } from '?/Composable/Translation';
    import repository from '?/Composable/Repository';
    import emitter from '?/Composable/Events/Emitter';
    import { routeTo, getAssetPath, formatDistance, scrollToBottom } from '?/Composable/Tools';
    import InteractionModal from '?/Component/ModalContent/Interaction';
    import Messages from '?/Component/Webapp/Messages';
    import Messenger from '?/Component/Webapp/Messenger';

   // Initialization
   const route = useRoute();

    // Data
    const props = defineProps({
        station: {
            type: Object,
            required: true
        }
    });

    const state = reactive({
        station: props.station,
        stationVisible: false,
        collapsedDescription: null
    });

    const activateStationRef = ref();
    const cancelStationRef = ref();

    const currentStationData = projectData[route.meta.slug];

    // Custom setup logic
    if (
        store.getters.processedStations(route.meta.slug).length &&
        !store.getters.station(route.params.id)
    ) {
        routeTo('/webapp/' + route.meta.slug)
    }

    onMounted(() => {
        state.stationVisible = props.station.visible;

        // Delete messages only after opening view, if station is inactive
        if (state.station && !isActive()) {
            store.dispatch('deleteDataforStation', state.station.stationId);
        }

        // Re-activate station if messages are empty (client switch)
        if (
            state.station &&
            isActive() &&
            !store.getters.messagesforStation(state.station.stationId).length &&
            !store.getters.unreadMessagesforStation(state.station.stationId).length
        ) {
            repository.cancelStation(state.station.stationId)
                .then(() => {
                    repository.activateStation(state.station.stationId)
                        .then(() => {
                            // Focus cancelation button
                            setTimeout(() => {
                                if (cancelStationRef.value) {
                                    cancelStationRef.value.focus();
                                }
                            }, 50);
                        });
                });
        }
    });

    // Delete messages only after leaving view, if station is inactive
    onUnmounted(() => {
        if (state.station && !isActive()) {
            store.dispatch('deleteDataforStation', state.station.stationId);
        }
    });

    // Functions
    const activatable = () => {
        return (
            store.getters.currentStationId != state.station.stationId &&
            !state.station.done
        );
    };

    const isActive = () => {
        return store.getters.isStationActive(state.station.stationId);
    };

    const activate = () => {
        // Delete existing messages
        store.dispatch('deleteDataforStation', state.station.stationId);

        // Activate station
        repository.activateStation(state.station.stationId)
            .then(() =>{
                // Focus cancelation button
                setTimeout(() => {
                    if (cancelStationRef.value) {
                        cancelStationRef.value.focus();
                    }
                }, 100);

            });
    };

    const cancel = () => {
        // @event activate-modal
        emitter.emit('activate-modal', {
            component: InteractionModal,
            title: t('station.cancel-modal-title', {name: stationTitle(state.station)}),
            defaultCloseButton: false,
            data: {
                confirmationCallback: () => {
                    repository.cancelStation(state.station.stationId)
                        .then(() => {
                            // Immediately delete data
                            store.dispatch('deleteDataforStation', state.station.stationId);
                            store.dispatch('deleteDelayedMessages', state.station.stationId);

                            // Focus activation button
                            setTimeout(() => {
                                if (activateStationRef.value) {
                                    activateStationRef.value.focus();
                                }
                            }, 50);
                        });
                },
                cancelationCallback: () => {}
            }
        });
    };

    const stationTitle = (station) => {
        let data = currentStationData
            .filter((item) => {
                return (item.id == station.stationId);
            });

        return (data.length)
            ? (station.done && data.hasOwnProperty('titleDone'))
                ? data[0].titleDone
                : (data[0].title)
                    ? data[0].title
                    : station.name
            : '';
    };

    const stationDescription = (station) => {
        let data = currentStationData
            .filter((item) => {
                return (item.id == station.stationId);
            });

        return (data.length)
            ? (station.done && data.hasOwnProperty('longDescDone'))
                ? data[0].longDescDone
                : data[0].longDesc
            : '';
    };

    // Events
    const scrollEventFunction = (e) => {
        nextTick(() => {
            scrollToBottom('SingleStation');

            if (config.debug) {
                console.log(
                    'Scroll to bottom for: `SingleStation`',
                    'Event: ', e
                );
            }
        });
    };

    emitter.on('message-added', scrollEventFunction);
    emitter.on('answer-rejected-for-station', scrollEventFunction);
    onUnmounted(() => emitter.off('message-added', scrollEventFunction));
    onUnmounted(() => emitter.off('answer-rejected-for-station', scrollEventFunction));
 </script>

<template>
    <div id="station-main-detail"
         class="station-detail station-main-detail station-type__game-visibility"
         v-if="state.stationVisible">
        <div class="station"
             :id="'station-' + state.station.stationId">
            <h2 class="station-name"
                v-html="stationTitle(state.station)"></h2>
            <figure class="station-image">
                <span class="station-image-wrapper"
                      :style="'background-image: url(\'' + getAssetPath() + '/' + $route.meta.slug + '/station-' + state.station.stationId + '.jpg\');'">
                    <img :src="getAssetPath() + '/' + $route.meta.slug + '/station-' + state.station.stationId + '.jpg'"
                         alt="" />
                </span>
                <figcaption>
                    <button class="activate-station"
                            v-if="state.station.visible"
                            v-show="activatable()"
                            @click="activate()"
                            ref="activateStationRef">
                        {{ $t('station.activate') }}
                    </button>

                    <div class="station-status" v-if="isActive()">
                        {{ $t('station.running') }}
                    </div>

                    <div class="station-description"
                         v-html="stationDescription(state.station)"
                         :class="(!isActive() || state.collapsedDescription) ? ' uncollapsed' : 'collapsed'"
                         :data-collapsed-label="$t('station.more')"
                         @click="state.collapsedDescription = !state.collapsedDescription"></div>
                    <div class="station-distance" v-if="store.getters.geolocation">
                        {{ formatDistance(station.distance) }}
                    </div>
                    <div class="station-group" :id="state.station.groupName">
                        {{ state.station.groupName }}
                    </div>

                    <button class="cancel-station"
                            v-if="isActive()"
                            v-show="isActive()"
                            @click="cancel()"
                            ref="cancelStationRef">
                        {{ $t('station.cancel') }}
                    </button>
                </figcaption>
            </figure>
        </div>

        <Messages :stationId="state.station.stationId" />

        <template v-if="isActive()">
            <Messenger :stationId="state.station.stationId" />
        </template>
    </div>
    <div id="station-main-detail" class="station-detail station-main-detail" v-else>
        {{ $t('main.unavailable') }}
    </div>
</template>
