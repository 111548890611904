<!--
/**
 * Intro template
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */
 -->

<script setup>
    import { reactive } from 'vue';
    import store from '?/Stores';
    import { routeTo, getAssetPathForIntro } from '?/Composable/Tools';
    import AudioPlayer from '?/Component/Library/AudioPlayer';

    // Data
    const state = reactive({
        step: 1,
        stepCount: 4
    });
</script>

<template>
    <div class="intro-wrapper">
        <div class="intro-content">
            <template v-if="state.step === 1">
                <h2 class="h1">Sei gegrüßt</h2>
                <p>
                    Ich darf Dich willkommen heißen an einem Ort, wo die Bedeutung von gewohnten Grenzen aufgehoben wird.
                </p>
                <p>
                    Wenn Du im Browser Deine Standortdaten (GPS) freigibst, fällt es mir leichter Dich hier zu leiten.
                </p>
                <p>
                    Deine Daten werden weder gespeichert noch an den Server übertragen.
                </p>
            </template>
            <template v-if="state.step === 2">
                <h2 class="h1">Wo Du bist</h2>
                <p>
                    Diese WebApp ist eine Art von Vermittlungszentrale, die Dir Zutritt zu anderen Räumen gewährt.
                </p>
                <p>
                    Doch eins nach dem anderen. Zunächst möchte ich Dir eine kleine Einführung geben.
                </p>
            </template>
            <template v-if="state.step === 3">
                <h2 class="h1">Die Funktionen</h2>
                <img src="/assets/layout/webapp/icons/station.png" width="130" height="130" />
                <p>
                    Hier kannst Du die Grenze der Zeit und die scheinbaren Grenzen zwischen verschiedenen Realitäten überschreiten. Zeige mir, dass Du bereit dafür bist und ich bringe Dich hinüber.
                </p>
                <img src="/assets/layout/webapp/icons/story.png" width="130" height="130" />
                <p>
                    Ich habe meine eigene Geschichte. Doch ob ich sie mit Dir teile, hängt von Dir ab.
                </p>
                <img src="/assets/layout/webapp/icons/news.png" width="130" height="130" />
                <p>
                    Wenn es etwas Neues gibt, wirst Du es hier erfahren.
                </p>
            </template>
            <template v-if="state.step === 4">
                <h2 class="h1">Gleich können wir beginnen.</h2>
                <p>
                    Doch zuvor sollte ich mich kurz vorstellen.
                </p>
                <p>
                    <AudioPlayer :src="getAssetPathForIntro() + '/intro-1.mp3'" />
                </p>
            </template>
        </div>
        <div class="intro-controls">
            <button type="button"
                    class="intro-controls-previous"
                    v-if="state.step > 1"
                    @click="state.step--">
                {{ $t('intro.previous') }}
            </button>
            <button type="button"
                    class="intro-controls-next"
                    v-if="state.step < state.stepCount"
                    @click="state.step++">
                {{ $t('intro.next') }}
            </button>
            <button type="button"
                    class="intro-controls-final"
                    v-if="state.step === state.stepCount"
                    @click="routeTo('/webapp/station')">
                {{ $t('intro.go-to-stations') }}
            </button>
            <span class="intro-controls-meta">
                {{ state.step }}/{{ state.stepCount }}
            </span>
        </div>
    </div>
</template>
