/**
 * Get unseen stories
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */

import store from '?/Stores';
import { story as storyData } from '?/ProjectData';


/**
 * Get unseen stories
 *
 * @return {array} Unseen stories
 */
export const getUnseenStories = () => {
    const totalPoints = store.getters.stationsWithPointsCount;
    let stories = Object.values(store.getters.stories)
        .filter((item) => {
            // Filter by `live` status
            if (!item.live) {
                return false;
            }

            // Filter by points
            const currentStoryData = storyData.find((data) => {
                return data.id == item.storyId;
            });

            // Filter by `done`
            if (item.done) {
                return false;
            }

            if (currentStoryData) {
                return (currentStoryData.points <= totalPoints);
            }

            return false;
        });

    return stories;
};
